// @ts-nocheck
// Customizable Area Start
import React from "react";
import PropTypes from 'prop-types';
import BusEmployeesController from "./BusEmployeesController";
// @ts-ignore
import classesOuter from './BusFollowing.module.css'
import {
    Box, InputAdornment, Input, IconButton, Typography, Button, Table,
    TableRow,
    TableBody,
    Divider,
    CircularProgress,

} from '@material-ui/core'
import { icons } from "../../../components/src/constants";
import {
    withStyles,
} from "@material-ui/core/styles";
import { CustomDetailsDialog, CustomDialog, viewBtn, styles, tableHeading, TableCell, TableHead, noDataStyle, tableHeadPending, employeeTypeHeading } from "./BusRegisteredEmployee.web";



export class BusPendingEmployee extends BusEmployeesController {

    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    }
    getmainTable = () => {
        return (
            <TableBody className={classesOuter.followersNPTableCell}>
                {this.state.filteredPendingData.map((item: any, index: any) =>
                    <TableRow key={index} data-test-id={`tableData-${item.id}`} style={{ border: 0, borderBottom: "none" }}>
                        <TableCell>{item?.attributes?.full_name ? this.getFormatedFirstName(item?.attributes?.full_name) : "-"}</TableCell>
                        <TableCell>{item?.attributes?.full_name ? this.getFormatedLastName(item?.attributes?.full_name) : "-"}</TableCell>
                        <TableCell>{item?.attributes?.email !== null ? item?.attributes?.email : "N/A"}</TableCell>
                        <TableCell >{item.attributes.dbs_types.length !== 0 ? this.getDbsFormatTable(item.attributes.dbs_types) : '-'}</TableCell>
                        <TableCell>
                            <Button
                                variant="outlined"
                                data-testId={`viewBtn-${item.id}`}
                                onClick={() => this.handleViewMore(item)}

                                style={viewBtn}
                                color="primary"
                            >
                                View
                            </Button>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        )
    }

    tableBodyData() {
        return (
            this.state.filteredPendingData.length > 0 ?
                <Table style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className={classesOuter.followersNPTableHead}>
                        <TableRow>
                            {tableHeadPending?.map(item => <TableCell key={item} style={tableHeading}>{item}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    {this.getmainTable()}

                </Table>
                : <Typography
                    style={noDataStyle}
                >
                    Sorry, No data available
                </Typography>


        )
    }
    tableDataPending() {
        return (
            this.state.loader ? <div
                className={classesOuter.loaderStyle}
            >
                <CircularProgress style={{ color: "#009be2" }} />
            </div> : this.tableBodyData()
        )
    }


    render() {
        const { classes }: any = this.props;
        return (
            <div>
                <Box className={classesOuter.followersBusheadingDiv}>
                    <CustomDetailsDialog
                        headerText={"Pending Employees details"}
                        individualRegisteredDetails={this.state.individualRegisteredDetails}
                        openDetailsDialog={this.state.openDetailsDialog}
                        handleDetailsModal={this.handleDetailsModal}
                        onClickPopup={this.props.onClickPopup(this.state.individualRegisteredDetails?.attributes?.sid)}
                        getFormatedFirstName={this.getFormatedFirstName}
                        getFormatedLastName={this.getFormatedLastName}
                        handleRejectBtn={this.handleRejectBtn}
                        getDbsFormatDialog={this.getDbsFormatDialog}/>
                    <CustomDialog open={this.state.isConfirmOpen} handleRejectEmployee={this.handleRejectEmployee} handleClose={this.handleClose} details={this.state.individualPendingDetails} />

                    <Box className={classesOuter.followersBusheading}>
                        <Typography style={employeeTypeHeading}>Pending Employees Requests</Typography>
                    </Box>
                    <Box>
                        <div className={classes.search}>

                            <Input
                                data-testId="searchInput"
                                startAdornment={
                                    <InputAdornment
                                        position="start"
                                        style={{
                                            width: 40,
                                            marginLeft: -9,
                                        }}
                                    >
                                        <IconButton>
                                            <img
                                                src={icons.searchSVG}
                                                style={{
                                                    height: 25,
                                                    width: "auto",
                                                    opacity: 0.5555555,
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder="Search..."
                                onChange={(e) => this.searchPendingData(e.target.value)}
                                className={classesOuter.searchField}
                                disableUnderline
                            />
                        </div>
                    </Box>
                </Box>
                <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />
                <Box>
                    {this.tableDataPending()}
                </Box>
            </div >
        )
    }
}

export default withStyles(styles as any, { withTheme: true })(
    BusPendingEmployee as any
);
// Customizable Area End