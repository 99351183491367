// @ts-nocheck
// Customizable Area Start
import React from "react";
import PropTypes from 'prop-types';
import BusEmployeesController from "./BusEmployeesController";
// @ts-ignore
import classesOuter from './BusFollowing.module.css'
import {
    Box, InputAdornment, Input, IconButton, Typography, Button, Table,
    TableRow,
    TableCell as MuiTableCell, TableHead as MuiTableHead,
    TableBody,
    Divider,
    CircularProgress,
    Dialog,
    Grid,
    Avatar
} from '@material-ui/core'
import { icons, constants } from "../../../components/src/constants";
import { COLORS } from "../../../framework/src/Globals";
import {
    withStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import moment from "moment";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';



export const styles = (theme: Theme) =>
    createStyles({
        toolBar: {
            display: "flex",
            flexDirection: "row",
            padding: "0px",
        },
        search: {
            position: "relative",
            borderRadius: "8px",
            backgroundColor: COLORS.white,
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(0),
            },
        },



    });

export const TableCell: any = withStyles({
    root: {
        borderBottom: "none !important",
    },
})(MuiTableCell);

export const TableHead: any = withStyles({
    root: {
        borderBottom: "3px solid #eaeaea",
    },
})(MuiTableHead);

export const viewBtn = {
    width: 100,
    boxShadow: "none",
    textTransform: "capitalize",
    backgroundColor: " #009be2",
    color: "white ",
    fontFamily: "Rene_Bieder_Milliard_Medium",
    borderRadius: 10,
    marginRight: 13,
    marginLeft: 13,
    border: "none"
}

export const tableHeading = {
    fontFamily: "Rene_Bieder_Milliard_Medium",
    textWrap: "nowrap"
}
export const noDataStyle = {
    textAlign: "center",
    height: "50%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Rene_Bieder_Milliard_Medium",
}

export const rejectBtn = {
    boxShadow: "none",
    textTransform: "none",
    height: 45,
    backgroundColor: "#F22324",
    color: "white ",
    fontFamily: "Rene_Bieder_Milliard_SemiBold",
    borderRadius: 10,
    marginLeft: 13,
    width: '40%'
}

const tableHeadReg = ["FIRST NAME", "LAST NAME", "EMAIL ADDRESS", "DBS TYPE", "LAST LOGIN"]
export const tableHeadPending = ["FIRST NAME", "LAST NAME", "EMAIL ADDRESS", "DBS TYPE"]

export const employeeTypeHeading = { fontSize: '22px', fontFamily: "Rene_Bieder_Milliard_Medium" }
export const modalEmployeeHeading = { marginRight: '200px', fontFamily: "Rene_Bieder_Milliard_SemiBold", fontSize: '1.25rem', lineHeight: 1.6, letterSpacing: '0.0075em', padding: '20px 20px 20px 40px' }

export const modalSubHeading = { color: '#626262' }
export const CustomDialog = ({ open, handleClose, details, handleRejectEmployee }) => {
    return <Dialog
        open={open}
        data-test-id="modal-close"
        onClose={() => handleClose()}>

        <div className={classesOuter.confirmDialogStyle}>

            <div className={classesOuter.errorTextParent}>
                <ErrorOutlineIcon className={classesOuter.error} />
                <p className={classesOuter.rejectText}>Are you sure, want to reject  employee?</p>
            </div>
            <div className={classesOuter.buttonsParent}>
                <Button className={classesOuter.confirmBtn}
                    onClick={() => handleRejectEmployee(details?.id)}
                    data-test-id="dialog-submit-button">
                    Confirm
                </Button>
                <Button className={`${classesOuter.cancelBtn}`} data-test-id="cancelDialog"
                    onClick={() => handleClose()}>
                    Cancel
                </Button>
            </div>

        </div>


    </Dialog >
}
export const CustomDetailsDialog = ({headerText, individualRegisteredDetails,openDetailsDialog,onClickPopup, handleDetailsModal,getFormatedFirstName, getFormatedLastName, handleRejectBtn, getDbsFormatDialog, handleOpen }) => {
    return (<Dialog
        fullScreen={false}
        open={openDetailsDialog}
        onClose={() => handleDetailsModal()}
        aria-labelledby="responsive-dialog-title"
        data-testId='closeModel'
        PaperProps={{
            style: { borderRadius: 16 }
        }}>

        <div className={classesOuter.dialogHeadingcontainer}>
            <div style={modalEmployeeHeading}>{headerText}</div>
            <div style={{ marginRight: 20 }}><CancelOutlinedIcon data-testId='closeBtn' onClick={() =>handleDetailsModal()} style={{ cursor: "pointer" }} /></div>
        </div>

        <div className={classesOuter.displayFlex}>
            <Avatar style={{ height: '50px', width: '50px', borderRadius: '100%', margin: '0px 20px 20px 40px' }} alt={individualRegisteredDetails.attributes?.full_name} src={individualRegisteredDetails.attributes?.profile_picture?.url ? individualRegisteredDetails.attributes?.profile_picture?.url : individualRegisteredDetails.attributes?.full_name} />
            <div>
                <Typography style={{ fontWeight: 'bold' }}>{individualRegisteredDetails?.attributes?.full_name ? getFormatedFirstName(individualRegisteredDetails?.attributes?.full_name) : "-"} {individualRegisteredDetails?.attributes?.full_name ? getFormatedLastName(individualRegisteredDetails?.attributes?.full_name) : "-"}</Typography>
                <Typography style={{ color: '#646464', fontSize: '14px' }}>{individualRegisteredDetails?.attributes?.email}</Typography>
            </div>
        </div>
        <Divider />

        <div className={classesOuter.p15}>
            <Grid container justifyContent={'center'}>
                <Grid item xs={4}>
                    <Typography style={modalSubHeading}>Bio</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography>{individualRegisteredDetails.attributes?.bio ?? "-"}</Typography>
                </Grid>
            </Grid>
        </div>
        <Divider />

        <div className={classesOuter.p15}>
            <Grid container justifyContent={'center'}>
                <Grid item xs={4}>
                    <Typography style={modalSubHeading}>Contact Number</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography>+{individualRegisteredDetails.attributes?.contact_number ?? "-"}</Typography>
                </Grid>
            </Grid>
        </div>
        <Divider />

        <div className={classesOuter.p15}>
            <Grid container justifyContent={'center'}>
                <Grid item xs={4}>
                    <Typography style={modalSubHeading}>Address</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography>{individualRegisteredDetails?.attributes?.street_name}</Typography>
                    <Typography>{individualRegisteredDetails?.attributes?.area}</Typography>
                    <Typography>{individualRegisteredDetails?.attributes?.city}</Typography>
                    <Typography>{individualRegisteredDetails?.attributes?.country}</Typography>
                    <Typography>{individualRegisteredDetails?.attributes?.post_code}</Typography>
                </Grid>
            </Grid>
        </div>
        <Divider />

        <div className={classesOuter.p15}>
            <Grid container justifyContent={'center'}>
                <Grid item xs={4}>
                    <Typography style={modalSubHeading}>DBS</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography>{individualRegisteredDetails?.attributes?.dbs_types?.length > 0 ? getDbsFormatDialog(individualRegisteredDetails?.attributes?.dbs_types) : '-'} </Typography>
                </Grid>
            </Grid>
        </div>
        <Divider />



        <div style={{ padding: '25px 15px' }}>
            <Grid container justifyContent={'center'}>
                <Grid item xs={4}>
                    <div className={classesOuter.ml20}>
                    <img src={icons.chats} alt='img'  data-testId='messageu-btn' onClick={() => onClickPopup()} className={classesOuter.messageIconStyle} />
                     <p className={classesOuter.modalMessageText}>Message</p>
                    </div>
                </Grid>
               
                <Grid item xs={7}>
                    <div className={classesOuter.displayFlex}>
                        <Button
                            data-testId='rejectVounteer'
                            onClick={() =>handleRejectBtn()}
                            style={rejectBtn}
                            color="secondary"
                        >
                            Reject user
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>

    </Dialog>
    )
}

export class BusRegisteredEmployee extends BusEmployeesController {

    static propTypes: {
        classes: PropTypes.Validator<object>;
        theme: PropTypes.Validator<object>;
    }
    getTableBody = () => {
        return (<TableBody className={classesOuter.followersNPTableCell}>
            {this.state?.filteredRegisteredData.map((item: any, index: any) =>
                <TableRow key={index} data-test-id={`tableData-${item.id}`} style={{ border: 0, borderBottom: "none" }}>
                    <TableCell>{item?.attributes?.full_name ? this.getFormatedFirstName(item?.attributes?.full_name) : ""}</TableCell>
                    <TableCell>{item?.attributes?.full_name ? this.getFormatedLastName(item?.attributes?.full_name) : ""}</TableCell>
                    <TableCell>{item?.attributes?.email !== null ? item?.attributes?.email : "N/A"}</TableCell>
                    <TableCell >{item.attributes.dbs_types.length !== 0 ? this.getDbsFormatTable(item.attributes.dbs_types) : '-'}</TableCell>
                    <TableCell >{item?.attributes?.last_login ? moment(item?.attributes?.last_login).format('DD/MM/YYYY h:mm') : moment(item?.attributes?.created_at).format('DD/MM/YYYY h:mm')}</TableCell>

                    <TableCell>
                        <Button
                            variant="outlined"
                            data-testId={`viewBtn-${item.id}`}
                            onClick={() => this.handleViewMore(item)}
                            style={viewBtn}
                            color="primary"
                        >
                            View
                        </Button>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>)
    }


    tableBodyData() {
        return (
            this.state.filteredRegisteredData.length > 0 ?
                <Table style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className={classesOuter.followersNPTableHead}>
                        <TableRow>
                            {tableHeadReg?.map(item => <TableCell key={item} style={tableHeading}>{item}</TableCell>
                            )}

                        </TableRow>
                    </TableHead>
                    {this.getTableBody()}

                </Table >
                : <Typography
                    style={noDataStyle}
                >
                    Sorry, No data available
                </Typography>


        )
    }
    tableDataRegistered() {
        return (
            this.state.loader ? <div
                className={classesOuter.loaderStyle}
            >
                <CircularProgress style={{ color: "#009be2" }} />
            </div> : this.tableBodyData()
        )
    }


    render() {
        const { classes }: any = this.props;
        return (
            <div>
                <Box className={classesOuter.followersBusheadingDiv}>
                    <CustomDetailsDialog
                        headerText={"Registered Employees details"}
                        individualRegisteredDetails={this.state.individualRegisteredDetails}
                        openDetailsDialog={this.state.openDetailsDialog}
                        handleDetailsModal={this.handleDetailsModal}
                        getFormatedFirstName={this.getFormatedFirstName}
                        getFormatedLastName={this.getFormatedLastName}
                        onClickPopup={() => this.props.onClickPopup(this.state.individualRegisteredDetails?.attributes?.sid)}
                        handleRejectBtn={this.handleRejectBtn}
                        getDbsFormatDialog={this.getDbsFormatDialog}
                        navigateToPlanChangePage={this.navigateToPlanChangePage}/>
                    <CustomDialog open={this.state.isConfirmOpen} handleRejectEmployee={this.handleRejectEmployee} handleClose={this.handleClose} details={this.state.individualRegisteredDetails} />
                    <Box className={classesOuter.followersBusheading}>
                        <Typography style={employeeTypeHeading}>Registered Employees</Typography>
                    </Box>
                    <Box>
                        <div className={classes.search}>

                            <Input
                                data-testId="searchInput"
                                startAdornment={
                                    <InputAdornment
                                        position="start"
                                        style={{
                                            width: 40,
                                            marginLeft: -9,
                                        }}
                                    >
                                        <IconButton>
                                            <img
                                                src={icons.searchSVG}
                                                style={{
                                                    height: 25,
                                                    width: "auto",
                                                    opacity: 0.5555555,
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder="Search..."
                                onChange={(e) => this.searchRegisteredData(e.target.value)}
                                className={classesOuter.searchField}
                                disableUnderline
                            />
                        </div>
                    </Box>
                </Box>
                <Divider style={{ backgroundColor: "#eaeaea", height: 2 }} />
                <Box>
                    {this.tableDataRegistered()}
                </Box>
            </div >
        )
    }
}

export default withStyles(styles as any, { withTheme: true })(
    BusRegisteredEmployee as any
);
// Customizable Area End